// CARDS BASIC
.card {
    border: 0;
    .card-title a {
        color: #424242;
        @include transition (0.4s);
        &:hover {
            color: #616161;
            @include transition (0.4s);
        }
    }
    img {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        @extend .img-fluid;
    }
    .card-footer {
        padding-bottom: 0;
    }
}

// Jumbtotron
.jumbotron {
    background-color: #fff;
    padding: 2rem;
    &.m-1,
    &.m-2,
    &.m-3 {
        z-index: 50;
        position: relative;
        margin-left: 3%;
        margin-right: 3%;
    }
    &.m-1 {
        margin-top: -20px;
    }
    &.m-2 {
        margin-top: -30px;
    }
    &.m-3 {
        margin-top: -40px;
    }
}

// .list-group fix
.list-group-item {
    width:100%;
}

// edge header
.edge-header {
    display:block;
    height:278px;
    background-color:#ccc;
}

.free-bird {
    margin-top:-100px;
}