
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/tether/src/css/tether";
//@import "node_modules/bootstrap/scss/bootstrap";\
//@import "node_modules/mdbootstrap/sass/mdb";

@import "public/mdb/sass/mdb";
@import "node_modules/vue-multiselect/dist/vue-multiselect.min";
//@import "node_modules/animate.css/animate.css";

//@import "public/css/materialdesignicons";

@import "customskin";

$body-bg:#eee;

[v-cloak] { display:none; }

body{
  background-color: $body-bg!important;
}

.md-form i{
  color : #757575;
}

.centerContainer .container.centered{
  margin-top:-4rem;
}

.centerContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  position : fixed;
  width:100%;
  height:100%;
  top:0;
  left:0;
}

.faded{
  background-color: rgba(255,255,255,.5);
}

.loader{
  z-index:10000;
}

.centerChildren{
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;

}

/*
Hacks to correct for theming problems
 */
$hack_color : $special-color;
.navbar-nav .nav-item.active {
  background-color: rgba(255,255,255,.1);
}

.dropdown .dropdown-menu .dropdown-item.active{
  background-color: rgba(169,169,169,.4);
}

.dropdown .dropdown-menu.dropdown-primary .dropdown-item:hover{
  background-color : $hack_color!important;
}

.pagination.pg-blue .active .page-link {
  background-color: $hack_color!important;
}

/*
End hacks
 */

.test{
  background-color : red!important;
}

#toast-container > div {
  opacity:0.99;
}