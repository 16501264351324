// NAVBARS
.navbar {
    form {
        float: right;
        input {
            margin: 0;
            height: 1rem;
            margin-right: 5px;
            margin-left: 8px;
            margin-bottom: 1px;
        }
    }
    .navbar-nav .nav-item {
        margin-left: 0;
        a {
            padding-right: 8px;
            padding-left: 8px;
            font-weight: 300;
        }
    }
    .navbar-toggler {
        float: right;
        background:none;
        border-width:0;
        &:focus {
            background-color: inherit;
        }
    }
    .breadcrumb {
        margin: 0;
        background-color: inherit;
        font-weight: 300;
    }
    .nav-flex-icons {
        float: right;
        display: flex;
    }
    @media only screen and (max-width: 992px) {
        // Non-fixed Navbar on smaller devices
        &.mobile-nofixed {
            position: relative;
        }
    }
    &.navbar-dark {
        .navbar-brand {
            color:#fff;
            &:hover {
                color:#fff;
            }
        }
        .breadcrumb,
        .navbar-nav .nav-item {
            a {
                color: #fff;
                transition: .35s;
                &:hover {
                    transition: .35s;
                    color: rgba(255, 255, 255, .75);
                }
            }
            &.active {
                background-color: rgba(255, 255, 255, 0.1);
            }
            .dropdown-menu a {
                color: #212121;
                padding: 10px;
            }
        }
        .navbar-toggler {
            color: #fff;
        }
        .nav-link {
            color: #fff;
        }
    }
    &.navbar-light {
        .breadcrumb,
        .nav-item {
            a {
                color: #212121;
                transition: .35s;
                &:hover {
                    transition: .35s;
                    color: #212121;
                }
            }
            &.active {
                background-color: rgba(158, 158, 158, 0.2);
            }
            .dropdown-menu a {
                color: #000;
                padding: 10px;
            }
        }
        .navbar-toggler {
            color: #000;
        }
    }
    @media (max-width: 544px) {
        .navbar-toggleable-xs {
            .navbar-brand {
                float: none;
                margin-left: 8px;
            }
            ul {
                float: none;
            }
            ul li {
                float: none;
            }
            form {
                float: left;
            }
            .nav-item {
                margin-left: 0;
            }
        }
    }
    @media (max-width: 768px) {
        .navbar-toggleable-sm {
            .navbar-brand {
                float: none;
            }
            ul {
                float: none;
            }
            ul li {
                float: none;
            }
            form {
                float: left;
                padding-left: 4px;
            }
            .nav-item {
                margin-left: 0;
            }
        }
    }
}

// Input line color
.navbar-dark form {
    // Style Placeholders
    ::-webkit-input-placeholder {
        color: #fff!important;
        font-weight: 300;
    }
    :-moz-placeholder {
        /* Firefox 18- */
        color: #fff!important;
        font-weight: 300;
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        color: #fff!important;
        font-weight: 300;
    }
    :-ms-input-placeholder {
        color: #fff!important;
        font-weight: 300;
    }
    input[type=text] {
        border-bottom: 1px solid #fff;
    }
    .form-control {
        color: #fff;
    }
}

.navbar-light form {
    // Style Placeholders
    ::-webkit-input-placeholder {
        color: #1C2331!important;
        font-weight: 300;
    }
    :-moz-placeholder {
        /* Firefox 18- */
        color: #1C2331!important;
        font-weight: 300;
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        color: #1C2331!important;
        font-weight: 300;
    }
    :-ms-input-placeholder {
        color: #1C2331!important;
        font-weight: 300;
    }
    input[type=text] {
        border-bottom: 1px solid #1C2331;
    }
    .form-control {
        color: #1C2331;
    }
}