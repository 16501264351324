// CAROUSELS PRO
.carousel-multi-item {
    margin-bottom: 100px;
    .carousel-indicators li {
        height: 20px;
        width: 20px;
        background-color: $primary-color;
        margin-bottom: -60px;
    }
    .carousel-indicators .active {
        height: 25px;
        width: 25px;
        background-color: $primary-color;
        @include border-radius(50%);
    }
    .controls-top {
        text-align: center;
        margin-bottom: 30px;
        .btn-floating {
            background: $primary-color;
        }
    }
    .carousel-indicators {
        margin-bottom: -2em;
    }
    .card {
        margin: 1px;
    }
    .card-cascade.narrower {
        margin-top: 20px;
        margin-bottom: 5px;
    }
}

@media only screen and (max-width: 992px) {
    .carousel-multi-item {
        .carousel-indicators li {
            margin-bottom: -30px;
        }
    }
}

//Carousel with thumbnails
.carousel-thumbnails {
    margin-bottom: 80px;
    .carousel-indicators {
        margin-bottom: -90px;
        position: absolute;
        li {
            height: auto;
            width: auto;
            border: none;
        }
        .active {
            height: auto;
            width: auto;
            background-color: #fff;
            opacity: 0.5;
        }
        img {
            width: 100px;
            height: 50px;
            overflow: hidden;
        }
    }
}

@media only screen and (max-width: 600px) {
    .carousel-thumbnails {
        margin-bottom: 60px;
        .carousel-indicators {
            margin-bottom: -60px;
            img {
            width: 50px;
            height: 25px;
        }
        }
    }
}