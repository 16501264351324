// Features

// Feature box
.feature-box {
    text-align: center;
   
    .feature-title {
        font-weight: 500;
        margin-bottom: 1rem;
    }
    .features-big {
        .feature-title {
            margin-top: 2rem;
            margin-bottom: 1rem;
        }
        .fa {
            font-size: 4rem;
        }
    }
    .features-small {
        text-align: left;
        .fa {
            font-size: 2rem;
        }
    }
}