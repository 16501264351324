
$custom-skin-color: 	#800000;
$custom-skin-gradient-start: #800000;
$custom-skin-gradient-end: #ff4444;


$custom-primary-color:#ff4444;
$custom-secondary-color:#007E33;

$primary-color-dark:#ff4444;



.custom-gradient {


  background: $custom-skin-gradient-start; /* Old browsers */
  background: -moz-linear-gradient(-45deg,  $custom-skin-gradient-start 0%, $custom-skin-gradient-end 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,  $custom-skin-gradient-start 0%,$custom-skin-gradient-end 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,  $custom-skin-gradient-start 0%,$custom-skin-gradient-end 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0049BC', endColorstr='#009FE3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}


.custom-skin {
  .primary-color {
    background-color: $custom-skin-color!important;
  }
  .navbar,
  .page-footer {
    background-color: lighten( $custom-skin-color, 2%);
  }
  .side-nav {
    @extend .custom-gradient;
    .logo-wrapper {
      background: url("http://mdbootstrap.com/images/sidenavs/blue.jpg") no-repeat center center;
      background-size: cover;
    }
    .sn-avatar-wrapper img {
      border: 3px solid lighten( $primary-color, 5%);
    }
    .social a {
      &:hover {
        background-color: lighten( $custom-skin-color, 0%);
        @include transition (all 0.3s linear);
      }
    }
    .collapsible li {
      background-color: transparent;
      a {
        &.active {
          background-color: $custom-skin-color;
        }
        &:hover {
          background-color: lighten( $custom-skin-color, 1%);
          @include transition (all 0.3s linear);
        }
      }
    }
    .fa {
      color: rgba(227, 242, 253, 0.64);
    }
  }
  .navbar .navbar-nav .dropdown .dropdown-primary .dropdown-item:hover {
    background-color: lighten( $custom-skin-color, 0%);
  }
  .btn-primary {
    background: $custom-primary-color;
    &:hover,
    &:focus {
      background-color: lighten( $custom-primary-color, 5%)!important;
    }
    &.active {
      background-color: darken( $custom-primary-color, 20%)!important;
    }
  }
  .btn-secondary {
    background: $custom-secondary-color;
    &:hover,
    &:focus {
      background-color: lighten( $custom-secondary-color, 5%)!important;
    }
    &.active {
      background-color: darken( $custom-secondary-color, 20%)!important;
    }
  }
  .btn-default,
  .card .btn-action {
    background: $primary-color-dark;
    &:hover,
    &:focus {
      background-color: lighten( $primary-color-dark, 5%)!important;
    }
    &.active {
      background-color: darken( $primary-color-dark, 20%)!important;
    }
  }
  .btn-primary-outline {
    border: 2px solid $primary-color;
    color: $primary-color!important;
  }
  .btn-secondary-outline {
    border: 2px solid $custom-secondary-color;
    color: $custom-secondary-color!important;
  }
  .btn-default-outline {
    border: 2px solid $primary-color-dark;
    color: $primary-color-dark!important;
  }

  input[type=checkbox].filled-in:checked {
    &+label {
      &:before {
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
      }

      &:after {
        background-color: $primary-color-dark;
        border-color: $primary-color-dark;
      }
    }
  }

  .top-nav-collapse {
    background-color: lighten( $custom-skin-color, 2%);
  }

  .carousel-multi-item {
    .controls-top > a, .carousel-indicators li, .carousel-indicators li.active {
      background-color: lighten( $custom-skin-color, 2%);
    }
  }
  .tag, .form-header, .card-header {
    background-color: lighten( $custom-skin-color, 2%);
  }
}